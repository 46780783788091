.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .login-form {
    padding: 36px 0 0 0;
    .login-logo {
      text-align: center;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      margin-bottom: 24px;
      span {
        vertical-align: text-bottom;
        font-size: 16px;
        text-transform: uppercase;
        display: inline-block;
      }
    }
  }
}

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .register-form {
    padding: 36px 0 0 0;
    .login-logo {
      text-align: center;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      margin-bottom: 24px;
      span {
        vertical-align: text-bottom;
        font-size: 16px;
        text-transform: uppercase;
        display: inline-block;
      }
    }
  }
}

.reprovision {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .reprovision-form {
    padding: 36px 0 0 0;
    .login-logo {
      text-align: center;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      margin-bottom: 24px;
      span {
        vertical-align: text-bottom;
        font-size: 16px;
        text-transform: uppercase;
        display: inline-block;
      }
    }
  }
}
.installer {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 100px 0;
  border-color: transparent #313653 transparent transparent;
  position: fixed;
  top: 0;
  right: 0;
}
.installer__btn {
  width: 50px;
  height: 50px;
  transform: rotate(45deg);
  left: 50px;
  position: absolute;
  background: url('./imgs/installer.png') no-repeat;
  background-size: cover;
}
